/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
'use client';

import '@livechat/design-system-react-components/dist/style.css';
import '@teamchat-shared/styles/github-markdown.css';

import { ThemeProvider, Global } from '@emotion/react';
import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { Button } from '@livechat/design-system-react-components';
import * as Sentry from '@sentry/nextjs';
import { Inter } from 'next/font/google';
import { useEffect } from 'react';
import { LogoApp as Logo } from '@teamchat-shared/components/Logo';
import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';
import { globalStyles } from '@teamchat-shared/styles/global';
import { theme } from '@teamchat-shared/styles/theme';

const inter = Inter({ subsets: ['latin'] });

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error('global error', error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles(true)} />
      <html lang="en">
        <head>
          <link rel="shortcut icon" href="/favicon.ico" />
        </head>

        <body className={'lc-light-theme'}>
          <Box
            as="nav"
            m="0 auto"
            p={{
              _: '0 1.5rem',
            }}
            maxWidth={1360}
            height={90}
            display="flex"
            alignItems="center"
            backgroundColor={`var(${DesignToken.ContentLockedWhite})`}
            position="fixed"
            top={0}
            left={0}
            right={0}
            zIndex={1000}
          >
            <Box display="flex" justifyContent="space-between" width={1}>
              <h1>
                <Logo />
              </h1>
            </Box>

            <Box
              height={{
                _: '60px',
                md: '90px',
              }}
              position="absolute"
              top={90}
              left={0}
              right={0}
              zIndex={1}
              css={css`
              background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--surface-primary-default) 100%);
          }
        `}
            />
          </Box>

          <Box
            mt={{
              _: '110px',
              md: '160px',
            }}
            as="main"
            p={{
              _: '1.5rem',
              md: '0',
            }}
            className={inter.className}
          >
            <Box
              maxWidth={1360}
              mx="auto"
              my="20px"
              px={{
                _: '0',
                md: 6,
              }}
            >
              <Box
                as="section"
                display="flex"
                justifyContent="center"
                mt="280px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="8px"
                >
                  <Box>
                    <Text
                      letterSpacing="-0.3px"
                      lineHeight="1.4em"
                      fontSize={{
                        _: '20px',
                        md: '28px',
                      }}
                      fontWeight={600}
                    >
                      Application error
                    </Text>
                  </Box>

                  <Box
                    mb="20px"
                    css={css`
                      text-align: center;
                    `}
                  >
                    <Text letterSpacing="-0.3px" lineHeight="1.4em">
                      Unexpected Error. Please try again or contact support.
                    </Text>
                  </Box>

                  <Button kind="primary" onClick={() => reset()}>
                    Please try again
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </body>
      </html>
    </ThemeProvider>
  );
}
